import { useState, useEffect, useRef, useCallback } from "react";
import "./AccountEnquiryEdit.scss";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Box,
  Tab,
  Tabs,
  AppBar,
  Avatar,
  MenuItem,
} from "@material-ui/core";
import { Button, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "../../../../commons/tooltip";
import Snackbar from "../../../../Confirmbox/Snackbar";
import CustomizedSnackbars from "../../../../Confirmbox/Snackbar";
import StartContribution from "./StartContribution";
import {
  createNewAccountForExistingMember,
  InitiateContribution,
} from "../../../../Api/Hooks-manager/accountOpening/existingCustomer";

import {
  pasportphotoupdate,
  downloadUploadedPhoto,
} from "../../../../Api/Hooks-manager/accountEnquiry/PassportModification";
import {
  openResquestDecisionModal,
  isLoading,
} from "../../../../Api/redux-manager/actions";

import { getAllStatesByCountryCode } from "../../../../Api/places/getPlaces.js";
import { format } from "date-fns";
import { getBankParameterByName } from "../../../../Api/Hooks-manager/coreModules/coreModules";
import SearchDropdown from "../../../../components/SearchableDropdown/Search";
import { getAllAccountOfficers } from "../../../../Api/Hooks-manager/accountEnquiry/accounteEnquiry";
import useCustom from "../../../../Api/Hooks-manager/accountOpening/useCustom";
import useCustom2 from "../../../../Api/Hooks-manager/coreModules/useCustom";
import useCustom22 from "../../../../Api/Hooks-manager/products/useCustom";
import { DateConverter } from "../../../ConvertDate";
import { Routes } from "../../../../Api/configUrl";
import EditModal from "../../../DatagridTable/edit";
import BasicModal from "./CustomModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    "&.MuiAppBar-colorPrimary": {
      backgroundColor: "#f7e7e9",
    },
  },
  text: {
    margin: "auto",
    marginTop: "1rem",
    color: theme.palette.secondary.main,
  },
  hr: {
    backgroundColor: "black",
    width: "100%",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  signatories: {
    border: "2px solid #f7e7e9",
    marginBottom: "3rem",
    padding: "2rem",
    "& input": {
      height: 44,
      borderRadius: 5,
      width: "100%",
    },
    "& select": {
      height: 44,
      borderRadius: 5,
      width: "100%",
    },
    "& label": {
      marginBottom: "-0.5rem",
    },
  },
  checkbtn: {
    width: "20%",
    height: "40px",
    marginBottom: "2rem",
    border: "2px solid #D0021B",
    color: "#D0021B",
    borderRadius: 5,
    backgroundColor: "white",
  },
  btndMain: {
    // display: "flex",
    // justifyContent: "end",
    // border: "2px solid blue",
  },
  btnd: {
    width: "100%",
    height: "40px",
    marginTop: "1.5rem",
    border: "2px solid #D0021B",
    color: "#D0021B",
    borderRadius: 5,
    backgroundColor: "white",
  },
  image: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    backgroundColor: theme.palette.secondary.main,
  },
  mandate: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
  },
  notApplicable: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "60vh",
    width: "65vw",
    border: "1px solid black",
  },
  table: {
    width: "100%",
    "& th": {
      paddingTop: 12,
      paddingBottom: 12,
      textAlign: "left",
    },
    "& td": {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
}));

const EditData = ({ details, showTooltip }) => {
  const classes = useStyles();
  const fileInputRef = useRef();
  const fileInputRef2 = useRef();
  const token = useSelector((state) => state.common.userDetails.jwtToken);
  const countries = useSelector((state) => state.common.countries);

  const openForm = useSelector((state) => state.req.openForm);
  const dispatch = useDispatch();
  const [states, setStates] = useState([]);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [url, setUrl] = useState(Routes.updateCustomerInfo);
  const [label, setLabel] = useState("Update customer info");
  const [value, setValue] = useState(0);
  const [data, setData] = useState({});
  const [image, setImage] = useState(null);
  const [sign, setSign] = useState(null);
  const [preview, setPreview] = useState("");
  const [signaturePreview, setSignaturePreview] = useState("");
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState(false);
  const [signatoryImages, setSignatoryImages] = useState({});
  const [signatoryEditedPassports, setSignatoryEditedPassports] = useState(
    Array(details.customer.accountSignatories.length).fill(null)
  );
  const [signatoryEditedMandates, setSignatoryEditedMandates] = useState(
    Array(details.customer.accountSignatories.length).fill(null)
  );

  // const [editData, setEditData] = useState(details)

  const { allbankparameters, getBankParametersAll } = useCustom2();

  const { allAccountProduct, getAccountDefinitionAll } = useCustom22();

  const parametersTitle = allbankparameters.filter((items) =>
    items.parameterName.toLowerCase().includes("cus_title")
  );

  const parametersIdType = allbankparameters.filter(
    (items) => items.parameterName === "ID_Type"
  );

  const parametersRelationship = allbankparameters.filter(
    (items) => items.parameterName === "NOK_Relationships"
  );

  const filteredAccountProduct = allAccountProduct.filter(
    (items) =>
      items.authorizationStatus === "Authorized" &&
      details.accountProduct.productName !== items.productName
  );

  const [editCustomerInfoData, setEditCustomerInfoData] = useState({});

  // console.log("edi consumer details", details);

  const [editNextOfKinData, setEditNextOfKinData] = useState({});

  const [accountOfficers, setAccountOfficers] = useState([]);

  const [editAccountSignatoriesData, setEditAccountSignatoriesData] = useState(
    []
  );

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarContent, setSnackBarContent] = useState({
    status: "",
    message: "",
  });

  const [editAccountInstructionData, setEditAccountInstructionData] = useState(
    {}
  );
  // const [editAccountInstructionData, setEditAccountInstructionData] = useState({
  //   accountType: details.accountType,
  //   accountName: details.accountName,
  //   accountNumber: details.accountNumber,
  //   alternateAccountNumber: details.alternateAccountNumber,
  //   oldAccountNumber: details.oldAccountNumber,
  //   accountProductCode: details.accountProductCode,
  //   currency: details.currency,
  //   atmNumber: details.atmNumber,
  //   accountOfficer: details.accountOfficer,
  //   enableSMS: details.enableSMS,
  //   enableEmail: details.enableEmail,
  //   accountInstruction1: details.accountInstruction1,
  //   accountInstruction2: details.accountInstruction2,
  //   accountInstruction3: details.accountInstruction3,
  //   id: details.id,
  // });

  const [existingData, setExistingData] = useState({
    productCode: "",
    customerId: details.customerId,
  });

  const parameters = "Account";

  const fetchAllAccountOfficers = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await getAllAccountOfficers(
      user.jwtToken,
      parameters,
      1,
      1000
    );
    if (response && response.data) {
      setAccountOfficers(
        response.data.map((item) => {
          return {
            value: `${item.firstName} ${item.middleName} ${item.lastName}`,
            label: `${item.firstName} ${item.middleName} ${item.lastName}`,
          };
        })
      );
    }
  };

  useEffect(() => {
    fetchAllAccountOfficers();
  }, []);

  useEffect(() => {
    getAccountDefinitionAll();
  }, [getAccountDefinitionAll]);

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditCustomerInfoData({
      ...editCustomerInfoData,
      [name]: value,
    });
    if (name === "country") {
      fetchState(value);
    }
  };

  const handleEditKinChange = (e, type) => {
    const { name, value } = e.target;
    setEditNextOfKinData({
      ...editNextOfKinData,
      [name]: value,
    });
    if (name === "country") {
      fetchState(value);
    }
  };

  const handleEditAccountSignatories = (e, index) => {
    const { name, value } = e.target;
    const lists = [...editAccountSignatoriesData];
    lists[index][name] = value;
    setEditAccountSignatoriesData(lists);
    if (name === "country") {
      fetchState(value);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleOpen = () => setOpen(true);
  const newHandleClose = () => setOpen(false);

  const handleEditAccountInstruction = (e) => {
    const { name, value } = e.target;
    setEditAccountInstructionData({
      ...editAccountInstructionData,
      [name]: value,
    });
  };
  const handleClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileChange = async (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    if (file && file.type.substring(0, 5) === "image") {
      const fileBase64 = await toBase64(file);
      // console.log(fileBase64);
      // setImage(fileBase64);
      if (name === "passport") {
        console.log(fileBase64);
        setPreview(fileBase64);
      } else if (name === "signature") {
        setSignaturePreview(fileBase64);
      }
    }
  };

  const handleFileChange2 = async (e) => {
    const { name } = e.target;

    const file2 = e.target.files[0];
    if (file2 && file2.type.substring(0, 5) === "image") {
      const fileBase64 = await toBase64(file2);
      // console.log(fileBase64);
      // setImage(fileBase64);
      if (name === "signature") {
        setSignaturePreview(fileBase64);
      }
    }
  };

  const handleNewChange = (e) => {
    const { name, value } = e.target;
    setExistingData((prev) => {
      return {
        ...prev, // Spread Operator
        [name]: value,
      };
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // console.log("newValue", newValue);
  };

  console.log("details...", details);

  useEffect(() => {
    if (value === 0) {
      setUrl(Routes.updateCustomerInfo);
      setLabel("Update customer info");
      setEditCustomerInfoData({
        // id: details.id,
        corporateName: details.customer?.corporateName,
        title: details.customer.title,
        firstName: details.customer.firstName,
        middleName: details.customer.middleName,
        lastName: details.customer.lastName,
        customerType: details.customer.customerType,
        email: details.customer.email,
        phoneNumber: details.customer.phoneNumber,
        gender: details.customer.gender,
        customerCategory: details.customer.customerCategory,
        dateOfBirth: DateConverter(details.customer.dateOfBirth),
        address: details.customer.address,
        city: details.customer.city,
        country: details.customer.country,
        state: details.customer.state,
        lga: details.customer.lga,
        nationality: details.customer.nationality,
        passport: details.customer.passport,
        signature: details.customer.signature,
        kyc: details.customer.kyc,
        bvn: details.customer.bvn,
        identificationType: details.customer.identificationType,
        identificationNumber: details.customer.identificationNumber,
        tin: details.customer.tin,
        nin: details.customer.nin,
        rcNumber: details.customer.rcNumber,

        id: details.customerId,
        customerId: details.customer.memberId,
        category: details.customer.category,
      });
    }
    if (value === 1 && details.customer.nextOfKin) {
      setUrl(Routes.updateNextOfKin);
      setLabel("Update next of kin");
      setEditNextOfKinData({
        firstName: details.customer.nextOfKin.firstName,
        middleName: details.customer.nextOfKin.middleName,
        lastName: details.customer.nextOfKin.lastName,
        qualifications: details.customer.nextOfKin.qualifications,
        relationship: details.customer.nextOfKin.relationship,
        gender: details.customer.nextOfKin.gender,
        profession: details.customer.nextOfKin.profession,
        dateOfBirth: DateConverter(details.customer.nextOfKin.dateOfBirth),
        address: details.customer.nextOfKin.address,
        maritalStatus: details.customer.nextOfKin.maritalStatus,
        nationality: details.customer.nextOfKin.nationality,
        id: details.customer.nextOfKin.id,
        customerId: details.customer.nextOfKin.customerId,
        isDependent: details?.customer?.nextOfKin?.isDependent,
        email: details?.customer?.nextOfKin?.email,
        phone: details?.customer?.nextOfKin?.phone,
      });
    }
    if (value === 2) {
      setUrl(Routes.updateAccounSignatories);
      setLabel("Update account signatory");
      setEditAccountSignatoriesData(
        details.customer.accountSignatories.map((item, index) => {
          return {
            customerIsASignatory: item.customerIsASignatory,
            title: item.title,
            firstname: item.firstname,
            middlename: item.middlename,
            lastname: item.lastname,
            gender: item.gender,
            email: item.email,
            phonenumber: item.phonenumber,
            address: item.address,
            country: item.country,
            state: item.state,
            kyc: item.kyc,
            bvn: item.bvn,
            identificationType: item.identificationType,
            identificationNumber: item.identificationNumber,
            id: item.id,
            customerId: item.customerId,
            nationality: item.nationality,
            passport: item.passport,
            signature: item.signature,
          };
        })
      );
    }

    if (value === 3) {
      setUrl(Routes.updateAccountInstructions);
      // setUrl(Routes.updateCustomerInfo);
      setLabel("Update account instructions");
      setEditAccountInstructionData((prev) => ({
        ...prev,
        accountType: details.accountType,
        accountName: details.accountName,
        accountNumber: details.accountNumber,
        alternateAccountNumber: "",
        oldAccountNumber: details.oldAccountNumber,
        accountProductCode: details.accountProductCode,
        // accountProductType: details.accountProduct.accountType,
        // productName: details.accountProduct.productName,
        currency: details.currency,
        atmNumber: details.atmNumber,
        accountOfficer: details.accountOfficer,
        enableSMS: details.enableSMS,
        enableEmail: details.enableEmail,
        accountInstruction1: details.accountInstruction1,
        accountInstruction2: details.accountInstruction2,
        accountInstruction3: details.accountInstruction3,
        // id: details.id,
      }));
    }
  }, [details, value]);

  const addSignatory = () => {
    const list = {
      customerIsASignatory: false,
      title: "",
      lastName: "",
      firstName: "",
      middleName: "",
      email: "",
      phonenumber: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      kyc: "",
      bvn: "",
      identificationType: "",
      identificationNumber: "",
      passport: "",
      signature: "",
    };
    setEditAccountSignatoriesData((prev) => [...prev, { ...list }]);
  };

  const removeSignatory = (index) => {
    const lists = [...editAccountSignatoriesData];
    if (lists.length < 2) return;
    lists.splice(index, 1);
    setEditAccountSignatoriesData((prev) => [...lists]);
  };

  const handleSubmit = () => {
    if (value === 0) {
      setData(editCustomerInfoData);
    }
    if (value === 1) {
      setData(editNextOfKinData);
    }
    if (value === 2) {
      setData(editAccountSignatoriesData[0]); //one signatory forn now until bulk edit for signatory endpoint is created
    }
    if (value === 3) {
      setData(editAccountInstructionData);
    }
    dispatch(openResquestDecisionModal());
  };

  const fetchState = async (value) => {
    dispatch(isLoading());
    const response = await getAllStatesByCountryCode(token, value);
    if (response && response.data) {
      dispatch(isLoading());
      setStates(response.data);
    }
  };

  useEffect(() => {
    //get customer types
    const parameter = "CUS_TYPE";
    const BankParameterByName = async () => {
      const response = await getBankParameterByName(token, parameter);
      if (response && response.data.cuS_TYPE) {
        setCustomerTypes(response.data.cuS_TYPE);
      }
      // console.log(response.data.cuS_TYPE, "customer typpppe");
    };

    BankParameterByName();
  }, [token]);

  useEffect(() => {
    getBankParametersAll();
  }, [getBankParametersAll]);

  // const getUploadedPassport = useCallback(
  //   async (url) => {
  //     const response = await downloadUploadedPhoto(token, url);
  //     // console.log("here we have the passport", response.data);
  //     if (response.status) {
  //       // setImage(response.data)
  //       setImage(`data:image/jpeg;base64,${response.data}`);
  //     }
  //   },
  //   [token]
  // );

  const getImages = useCallback(
    async (passportFileName, signatureFileName) => {
      const passportResponse = await downloadUploadedPhoto(
        token,
        passportFileName
      );
      const signatureResponse = await downloadUploadedPhoto(
        token,
        signatureFileName
      );
      if (signatureResponse.status) {
        // setImage(response.data)
        setSign(`data:image/jpeg;base64,${signatureResponse.data}`);
      } else {
        setSign({ sign });
      }

      if (passportResponse.status) {
        // setImage(passportResponse.data)
        setImage(`data:image/jpeg;base64,${passportResponse.data}`);
      } else {
        setImage({ image });
      }
    },
    [token]
  );

  // useEffect(()=>{
  //   if(details && details.customer && details.customer.passport){
  //   getUploadedPassport(details.customer.passport)
  //   }
  // },[details, getUploadedPassport])

  const editPassportPhoto = useCallback(async () => {
    const passport = preview || image;
    const signature = signaturePreview || sign;
    // console.log('passport..',passport);
    // console.log('signatuire..',signature);
    if (preview || signaturePreview) {
      dispatch(isLoading());
      const response = await pasportphotoupdate(
        token,
        details.customerId,
        passport,
        signature
      );
      if (response && response.data) {
        dispatch(isLoading());
        setOpen(true);
        if (response.message === "Success") {
          setStatus("success");
          setMessage("Succesfully uploaded");
          // setPreview('')
          // setEditCustomerInfoData((prev) => {
          //   return {
          //     ...prev,
          //     passport: response.data.passport
          //   }
          // })
          // console.log(response.data.passport, response.data.signature, "aaaaaaaaaaaaaaaaaaaaaaaaaaaa");
        } else {
          setStatus("error");
          setMessage("failed to upload");
        }
      }
    }
  }, [dispatch, token, details.customerId, preview, signaturePreview]);

  // useEffect(() => {
  //   if (image) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       const base64String = reader.result;
  //       setPreview(base64String);
  //     };
  //     reader.readAsDataURL(image);
  //   } else {
  //     setPreview("");
  //   }
  // }, [image]);

  // console.log("this is preview", preview)
  // useEffect(() => {
  //   if (preview) {
  //     editPassportPhoto();
  //   }
  // }, [preview, editPassportPhoto]);

  useEffect(() => {
    if (openForm) {
      getImages(editCustomerInfoData.passport, editCustomerInfoData.signature);
    }
  }, [
    editCustomerInfoData.passport,
    editCustomerInfoData.signature,
    getImages,
    openForm,
  ]);

  const onEditClose = () => {
    setSignaturePreview(null);
    setImage(null);
    setPreview(null);
    setSign(null);
    setSignatoryEditedPassports(
      Array(details.customer.accountSignatories.length).fill(null)
    );
    setSignatoryEditedMandates(
      Array(details.customer.accountSignatories.length).fill(null)
    );
  };

  const handleSignatoryFileChange = async (e, index) => {
    const { name } = e.target;
    const file = e.target.files[0];
    const encodedFile = await toBase64(file);
    if (name === "passport") {
      // const prev = [...editAccountSignatoriesData]
      // prev[index].passport = encodedFile
      // setEditAccountSignatoriesData(prev)
      setSignatoryEditedPassports((prevState) => {
        const newState = [...prevState];
        newState[index] = encodedFile;
        return newState;
      });
    }
    if (name === "signature") {
      // const prev = [...editAccountSignatoriesData]
      // prev[index].signature = encodedFile
      // setEditAccountSignatoriesData(prev)
      setSignatoryEditedMandates((prevState) => {
        const newState = [...prevState];
        newState[index] = encodedFile;
        return newState;
      });
    }
  };
  // console.log(signatoryEditedPassports, signatoryEditedMandates)

  useEffect(() => {
    const images = {};
    details.customer.accountSignatories.forEach(
      async ({ passport, signature }) => {
        if (passport) {
          const res = await downloadUploadedPhoto(token, passport);
          images[passport] = `data:image/jpeg;base64,${res.data}`;
        }
        if (signature) {
          const res = await downloadUploadedPhoto(token, signature);
          images[signature] = `data:image/jpeg;base64,${res.data}`;
        }
        setSignatoryImages(images);
      }
    );
  }, [details, token]);

  const createAccountForExistingCustomer = useCallback(
    async (preData) => {
      dispatch(isLoading());
      const response = await createNewAccountForExistingMember(token, preData);
      if (response && response.data) {
        console.log(response);
        dispatch(isLoading());
        setMessage(response.message);
        setResult(response.status);
        handleOpen();
      } else {
        setMessage(response.message);
        setResult(response.status);
        handleOpen();
      }
    },
    [dispatch, token]
  );

  // useEffect(() => {
  //   createAccountForExistingCustomer(existingData);
  // }, [existingData]);

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };

  const checkIfContributionHasStarted = details.contributionStarted === false;

  console.log("editAccountInstructionData", editAccountInstructionData);

  const userId =
    value === 1
      ? details?.customer?.nextOfKin?.id
      : value === 3
      ? details?.id
      : details?.customerId;
  return (
    <EditModal
      title="Customer Account - Record Details"
      data={data}
      question="Are you sure you want to update this Account?"
      url={`${url}${userId}`}
      label={label}
      onClick={handleSubmit}
      beforeAction={editPassportPhoto}
      onClose={onEditClose}
    >
      <div className={classes.root}>
        <CustomizedSnackbars
          handleClose={handleCloseSnackBar}
          message={snackBarContent.message}
          status={snackBarContent.status}
          open={snackBarOpen}
        />
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Customer Information" {...a11yProps(0)} />
            <Tab label="Next of Kin" {...a11yProps(1)} />
            {/* <Tab label="Account Information" {...a11yProps(2)} /> */}
            <Tab label="Account Signatories" {...a11yProps(2)} />
            <Tab label="Account Instruction" {...a11yProps(3)} />
            <Tab label="Add New Product" {...a11yProps(4)} />
            {checkIfContributionHasStarted && (
              <Tab label="Start Contribution" {...a11yProps(5)} />
            )}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2} className="account-enquiry">
            <Grid item xs={12} className={classes.image}>
              <Tooltip
                title={showTooltip ? "Add photo" : "Not permitted to upload"}
                show={showTooltip}
              >
                <Avatar
                  onClick={handleClick}
                  alt={
                    details.customer.firstName || details.customer.corporateName
                  }
                  // src={`data:image/jpeg;base64,${image}`}
                  src={preview || image}
                  className={classes.large}
                />
              </Tooltip>
              <div>
                <input
                  type="file"
                  ref={fileInputRef}
                  //style={{ display:'none'}}
                  accept="image/*, .pdf"
                  name="passport"
                  onChange={handleFileChange}
                  style={{
                    display: "flex",
                    borderRadius: 7,
                    marginLeft: 5,
                    width: "50%",
                  }}
                  //disabled={!showTooltip}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>Customer Type</label>
              <br />
              <select
                onChange={handleEditChange}
                name="customerType"
                value={editCustomerInfoData.customerType}
                disabled
              >
                <option value="">Select An Option</option>
                {customerTypes && customerTypes.length > 0
                  ? customerTypes.map((types, i) => {
                      // console.log(customerTypes, "what's is in edit")

                      return (
                        <option key={i} value={types.parameterValue}>
                          {types.parameterValue}
                        </option>
                      );
                    })
                  : null}
              </select>
            </Grid>
            {(editCustomerInfoData.customerType === "" ||
              editCustomerInfoData.customerType === "Individual") && (
              <Grid item xs={12} sm={6} md={3} className="new">
                <label>Title</label>
                <br />
                <select
                  value={editCustomerInfoData.title}
                  name="title"
                  onChange={handleEditChange}
                >
                  <option>Select An Option</option>
                  {parametersTitle.map((title, index) => {
                    return (
                      <option key={index} value={title.parameterValue}>
                        {title.parameterValue}
                      </option>
                    );
                  })}
                </select>
              </Grid>
            )}
            {editCustomerInfoData.customerType !== "" &&
              editCustomerInfoData.customerType !== "Individual" && (
                <Grid item xs={12} sm={6} md={3} className="new">
                  <label>RC Number</label>
                  <br />
                  <input
                    type="text"
                    onChange={handleEditChange}
                    name="rcNumber"
                    value={editCustomerInfoData.rcNumber}
                  />
                </Grid>
              )}
            {editCustomerInfoData.customerType !== "" &&
              editCustomerInfoData.customerType !== "Individual" && (
                <Grid item xs={12} sm={6} md={3} className="new">
                  <label>Organization Name</label>
                  <br />
                  <input
                    type="text"
                    onChange={handleEditChange}
                    name="corporateName"
                    value={editCustomerInfoData.corporateName}
                  />
                </Grid>
              )}
            {(editCustomerInfoData.customerType === "" ||
              editCustomerInfoData.customerType === "Individual") && (
              <Grid item xs={12} sm={6} md={3} className="new">
                <label>First Name</label>
                <br />
                <input
                  type="text"
                  onChange={handleEditChange}
                  name="firstName"
                  value={editCustomerInfoData.firstName}
                />
              </Grid>
            )}

            {(editCustomerInfoData.customerType === "" ||
              editCustomerInfoData.customerType === "Individual") && (
              <Grid item xs={12} sm={6} md={3} className="new">
                <label>Middle Name</label>
                <br />
                <input
                  type="text"
                  onChange={handleEditChange}
                  name="middleName"
                  value={editCustomerInfoData.middleName}
                />
              </Grid>
            )}
            {(editCustomerInfoData.customerType === "" ||
              editCustomerInfoData.customerType === "Individual") && (
              <Grid item xs={12} sm={6} md={3} className="new">
                <label>Last Name</label>
                <br />
                <input
                  type="text"
                  onChange={handleEditChange}
                  name="lastName"
                  value={editCustomerInfoData.lastName}
                />
              </Grid>
            )}
            {(editCustomerInfoData.customerType === "" ||
              editCustomerInfoData.customerType === "Individual") && (
              <Grid item xs={12} sm={6} md={3} className="new">
                <label>Customer ID</label>
                <br />
                <input
                  type="text"
                  onChange={handleEditChange}
                  name="memberId"
                  value={editCustomerInfoData.customerId || "None"}
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>Email</label>
              <br />
              <input
                type="email"
                name="email"
                onChange={handleEditChange}
                value={editCustomerInfoData.email}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>Phone</label>
              <br />
              <input
                type="tel"
                onChange={handleEditChange}
                name="phoneNumber"
                value={editCustomerInfoData.phoneNumber}
              />
            </Grid>
            {(editCustomerInfoData.customerType === "" ||
              editCustomerInfoData.customerType === "Individual") && (
              <Grid item xs={12} sm={6} md={3} className="new">
                <label>Gender</label>
                <br />
                <select
                  onChange={handleEditChange}
                  name="gender"
                  value={editCustomerInfoData.gender}
                >
                  <option>Select, An Option</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Not Applicable">Not Applicable</option>
                </select>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={3} className="new">
              {editCustomerInfoData.customerType === "" ||
              editCustomerInfoData.customerType === "Individual" ? (
                <label>Date of Birth</label>
              ) : (
                <label>Date of Incorporation</label>
              )}
              <br />
              <input
                type="date"
                onChange={handleEditChange}
                name="dateOfBirth"
                value={editCustomerInfoData.dateOfBirth}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>Address</label>
              <br />
              <input
                type="text"
                onChange={handleEditChange}
                name="address"
                value={editCustomerInfoData.address}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>City</label>
              <br />
              <input
                type="text"
                onChange={handleEditChange}
                name="city"
                value={editCustomerInfoData.city}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>LGA</label>
              <br />
              <input
                type="text"
                onChange={handleEditChange}
                name="lga"
                value={editCustomerInfoData.lga}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} className="new">
              <label>Country</label>
              <br />
              <select
                onChange={handleEditChange}
                name="country"
                value={editCustomerInfoData.country}
              >
                <option>Select An Option</option>
                {countries && countries.length > 0
                  ? countries.map((country, i) => {
                      return (
                        <option key={i} value={country.countryCode2}>
                          {country.name}
                        </option>
                      );
                    })
                  : null}
              </select>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>State</label>
              <br />
              <select
                onChange={handleEditChange}
                name="state"
                value={editCustomerInfoData.state}
              >
                <option>Select An Option</option>
                {states.map((state) => (
                  <option value={state.name} key={state.id}>
                    {state.name}
                  </option>
                ))}
              </select>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>Country of Origin</label>
              <br />
              <select
                onChange={handleEditChange}
                name="nationality"
                value={editCustomerInfoData.nationality}
              >
                <option>Select An Option</option>
                {countries && countries.length > 0
                  ? countries.map((country, i) => {
                      return (
                        <option key={i} value={country.countryCode2}>
                          {country.name}
                        </option>
                      );
                    })
                  : null}
              </select>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>Identification Type</label>
              <select
                name="identificationType"
                value={editCustomerInfoData.identificationType}
                onChange={handleEditChange}
              >
                <option>Select An Option</option>
                {parametersIdType.map((idtype, index) => {
                  return (
                    <option key={index} value={idtype.parameterValue}>
                      {idtype.parameterValue}
                    </option>
                  );
                })}
              </select>
              {/* <input
                type="text"
                onChange={handleEditChange}
                name="identificationType"
                value={editCustomerInfoData.identificationType}
                // disabled
              /> */}
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>Identification Number</label>
              <input
                type="text"
                onChange={handleEditChange}
                name="identificationNumber"
                value={editCustomerInfoData.identificationNumber}
                // disabled
              />
            </Grid>
            {/* <div className='input--field'>
                <label>Passport Photo</label>
                <input 
                    type='file'
                    onChange={(e) => handleChangeFile(e, 'passport')}
                    className={`input--file ${Object.keys(errors).includes('passport') && 'error-input-z'}`}
                    onClick={handleEditPhotoSubmit}
                    />
            </div>  */}
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>KYC</label>
              <br />
              <input
                type="text"
                onChange={handleEditChange}
                name="kyc"
                value={editCustomerInfoData.kyc}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>TIN</label>
              <br />
              <input
                type="text"
                onChange={handleEditChange}
                name="tin"
                value={editCustomerInfoData.tin}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>NIN</label>
              <br />
              <input
                type="text"
                onChange={handleEditChange}
                name="nin"
                value={editCustomerInfoData.nin}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>Type of Account Product</label>
              <br />
              <input
                type="text"
                onChange={handleEditChange}
                name="accountProductType"
                value={details.accountType}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>Account Product Code</label>
              <br />
              <input
                type="text"
                onChange={handleEditChange}
                name="accountProductCode"
                value={editAccountInstructionData.accountProductCode}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>Account Product Name</label>
              <br />
              <input
                type="text"
                onChange={handleEditChange}
                name="productName"
                value={details.accountProduct.productName}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>Signature/Mandate</label>
              <br />
              <img
                src={signaturePreview || sign}
                alt="mandate/signature"
                className={classes.mandate}
              />

              <div>
                <input
                  type="file"
                  ref={fileInputRef2}
                  //style={{ display:'none'}}
                  accept="image/*, .pdf"
                  name="signature"
                  onChange={handleFileChange}
                  style={{
                    display: "flex",
                    borderRadius: 7,
                    marginLeft: 5,
                    width: "50%",
                  }}
                  //disabled={!showTooltip}
                />
              </div>
            </Grid>
          </Grid>
        </TabPanel>
        {
          <TabPanel value={value} index={1}>
            {details.customer.nextOfKin && (
              <Grid container spacing={2} className="account-enquiry">
                <Grid item xs={12} sm={6} md={3} className="new">
                  <label>First Name</label>
                  <input
                    type="text"
                    value={editNextOfKinData.firstName}
                    onChange={handleEditKinChange}
                    name="firstName"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="new">
                  <label>Middle Name</label>
                  <input
                    type="text"
                    value={editNextOfKinData.middleName}
                    onChange={handleEditKinChange}
                    name="middleName"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="new">
                  <label>Last Name</label>
                  <input
                    type="text"
                    value={editNextOfKinData.lastName}
                    onChange={handleEditKinChange}
                    name="lastName"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} className="new">
                  <label>Qualification</label>
                  <input
                    type="text"
                    value={editNextOfKinData.qualifications}
                    onChange={handleEditKinChange}
                    name="qualifications"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="new">
                  <label>Relationship</label>
                  <select
                    value={editNextOfKinData.relationship}
                    onChange={handleEditKinChange}
                    name="relationship"
                  >
                    <option>Select An Option</option>
                    {parametersRelationship.map((title, index) => {
                      return (
                        <option key={index} value={title.parameterValue}>
                          {title.parameterValue}
                        </option>
                      );
                    })}
                  </select>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="new">
                  <label>Gender</label>
                  <select
                    value={editNextOfKinData.gender}
                    onChange={handleEditKinChange}
                    name="gender"
                  >
                    <option>Select An Option</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="new">
                  <label>Profession</label>
                  <input
                    type="text"
                    value={editNextOfKinData.profession}
                    onChange={handleEditKinChange}
                    name="profession"
                    maxLength="20"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="new">
                  <label>Address</label>
                  <input
                    type="text"
                    value={editNextOfKinData.address}
                    onChange={handleEditKinChange}
                    name="address"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="new">
                  <label>Date of Birth</label>
                  <input
                    type="date"
                    value={editNextOfKinData.dateOfBirth}
                    onChange={handleEditKinChange}
                    name="dateOfBirth"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="new">
                  <label>Marital Status</label>
                  <select
                    value={editNextOfKinData.maritalStatus}
                    onChange={handleEditKinChange}
                    name="maritalStatus"
                  >
                    <option>Select An Option</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Divorce">Divorce</option>
                    <option value="Widow">Widow</option>
                  </select>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="new">
                  <label>Nationality</label>
                  <br />
                  <select
                    onChange={handleEditKinChange}
                    name="nationality"
                    value={editCustomerInfoData.nationality}
                  >
                    <option>Select An Option</option>
                    {countries && countries.length > 0
                      ? countries.map((country, i) => {
                          return (
                            <option key={i} value={country.countryCode2}>
                              {country.name}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </Grid>
              </Grid>
            )}
            {!details.customer.nextOfKin && (
              <div className={classes.notApplicable}>
                <h2>Not applicable</h2>
              </div>
            )}
          </TabPanel>
        }
        <TabPanel value={value} index={2}>
          {(editCustomerInfoData.customerType === "" ||
            editCustomerInfoData.customerType === "Corporate" ||
            editCustomerInfoData.customerType === "Joint") && (
            <div>
              <button
                onClick={() => addSignatory()}
                className={classes.checkbtn}
              >
                Add New
              </button>
            </div>
          )}

          {editAccountSignatoriesData?.map((el, i) => (
            <Grid container spacing={2} className={classes.signatories} key={i}>
              {(editCustomerInfoData.customerType === "" ||
                editCustomerInfoData.customerType === "Individual" ||
                editCustomerInfoData.customerType === "Joint") && (
                <Grid item xs={12} sm={6} md={3}>
                  <label>Title</label>
                  <select
                    value={el.title}
                    name="title"
                    onChange={(e) => handleEditAccountSignatories(e, i)}
                  >
                    <option>Select An Option</option>
                    {parametersTitle.map((title, index) => {
                      return (
                        <option key={index} value={title.parameterValue}>
                          {title.parameterValue}
                        </option>
                      );
                    })}
                  </select>
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <label>First Name</label>
                <input
                  type="text"
                  value={el.firstname}
                  onChange={(e) => handleEditAccountSignatories(e, i)}
                  name="firstname"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <label>Middle Name</label>
                <input
                  type="text"
                  value={el.middlename}
                  onChange={(e) => handleEditAccountSignatories(e, i)}
                  name="middlename"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <label>Last Name</label>
                <input
                  type="text"
                  value={el.lastname}
                  onChange={(e) => handleEditAccountSignatories(e, i)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <label>Phone Number</label>
                <input
                  type="text"
                  value={el.phonenumber}
                  onChange={(e) => handleEditAccountSignatories(e, i)}
                  name="phonenumber"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <label>Gender</label>
                <select
                  value={el.gender}
                  onChange={(e) => handleEditAccountSignatories(e, i)}
                  name="gender"
                >
                  <option>Select An Option</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Binary">Binary</option>
                </select>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <label>Email</label>
                <input
                  type="text"
                  value={el.email}
                  onChange={(e) => handleEditAccountSignatories(e, i)}
                  name="email"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <label>Address</label>
                <input
                  type="text"
                  value={el.address}
                  onChange={(e) => handleEditAccountSignatories(e, i)}
                  name="address"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <label>Country</label>
                <select
                  onChange={(e) => handleEditAccountSignatories(e, i)}
                  name="country"
                  value={el.country}
                >
                  <option>Select An Option</option>
                  {countries && countries.length > 0
                    ? countries.map((country, i) => {
                        return (
                          <option key={i} value={country.countryCode2}>
                            {country.name}
                          </option>
                        );
                      })
                    : null}
                </select>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <label>State</label>
                <br />
                <select
                  onChange={(e) => handleEditAccountSignatories(e, i)}
                  name="state"
                  value={el.state}
                >
                  <option>Select An Option</option>
                  {states.map((state) => (
                    <option value={state.name} key={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <label>KYC</label>
                <input
                  type="text"
                  value={el.kyc}
                  onChange={(e) => handleEditAccountSignatories(e, i)}
                  name="kyc"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <label>BVN</label>
                <input
                  type="text"
                  value={el.bvn}
                  onChange={(e) => handleEditAccountSignatories(e, i)}
                  name="bvn"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} className="new">
                <label>Identification Type</label>
                <select
                  name="identificationType"
                  value={el.identificationType}
                  onChange={(e) => handleEditAccountSignatories(e, i)}
                >
                  <option>Select An Option</option>
                  {parametersIdType.map((idtype, index) => {
                    return (
                      <option key={index} value={idtype.parameterValue}>
                        {idtype.parameterValue}
                      </option>
                    );
                  })}
                </select>
                {/* <input
                type="text"
                onChange={handleEditChange}
                name="identificationType"
                value={editCustomerInfoData.identificationType}
                // disabled
              /> */}
              </Grid>
              <Grid item xs={12} sm={6} md={3} className="new">
                <label>Identification Number</label>
                <input
                  type="text"
                  onChange={(e) => handleEditAccountSignatories(e, i)}
                  name="identificationNumber"
                  value={el.identificationNumber}
                  // disabled
                />
              </Grid>
              <Grid
                container
                direction={"column"}
                xs={12}
                sm={12}
                md={6}
                lg={6}
                spacing={1}
              >
                <Grid item>
                  {(el.passport || signatoryEditedPassports[i]) && (
                    <>
                      <p>Passport</p>
                      <img
                        alt=""
                        src={
                          signatoryEditedPassports[i] ||
                          signatoryImages[el.passport]
                        }
                        className={classes.mandate}
                      />
                    </>
                  )}
                </Grid>

                {/* <Grid item >
                <input
                    name="passport"
                    type="file"
                    onChange={(e) => handleSignatoryFileChange(e, i)}
                  />
                </Grid> */}
              </Grid>

              <Grid
                container
                direction={"column"}
                xs={12}
                sm={12}
                md={6}
                lg={6}
                spacing={1}
              >
                <Grid item>
                  {(el.signature || signatoryEditedMandates[i]) && (
                    <>
                      <p>signature</p>
                      <img
                        alt=""
                        src={
                          signatoryEditedMandates[i] ||
                          signatoryImages[el.signature]
                        }
                        className={classes.mandate}
                      />
                    </>
                  )}
                </Grid>

                {/* <Grid item >
                  <input
                    name="signature"
                    type="file"
                    onChange={(e) => handleSignatoryFileChange(e, i)}
                  />
                </Grid> */}
              </Grid>

              {/* <Grid item xs={12} sm={6} md={4} lg={3}></Grid> */}
              {/* <Grid item xs={12} sm={6} md={4} lg={3}></Grid> */}
              {(editCustomerInfoData.customerType === "" ||
                editCustomerInfoData.customerType === "Corporate" ||
                editCustomerInfoData.customerType === "Joint") && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className={classes.btndMain}
                >
                  <button
                    className={classes.btnd}
                    onClick={() => removeSignatory(i)}
                  >
                    Delete
                  </button>
                </Grid>
              )}
            </Grid>
          ))}
        </TabPanel>

        <TabPanel value={value} index={3} className="account-enquiry">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>Account Name</label>
              <input
                type="text"
                value={editAccountInstructionData.accountName}
                onChange={handleEditAccountInstruction}
                name="accountName"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>Account Officer</label>
              <br />
              <select
                value={editAccountInstructionData.accountOfficer}
                onChange={handleEditAccountInstruction}
                name="accountOfficer"
              >
                <option>Select An Option</option>
                {accountOfficers && accountOfficers.length > 0
                  ? accountOfficers.map((officer, i) => {
                      return (
                        <option key={i} value={officer.value}>
                          {officer.value}
                        </option>
                      );
                    })
                  : null}
              </select>
              {/* <div
              >
                <SearchDropdown
                  options={accountOfficers}
                  onChange={handleEditChange}
                  type="loanOfficer"
                />
              </div> */}
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>Account Instruction1</label>
              <input
                type="text"
                value={editAccountInstructionData.accountInstruction1}
                onChange={handleEditAccountInstruction}
                name="accountInstruction1"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>Account Instruction2</label>
              <input
                type="text"
                value={editAccountInstructionData.accountInstruction2}
                onChange={handleEditAccountInstruction}
                name="accountInstruction2"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="new">
              <label>Account Instruction3</label>
              <input
                type="text"
                value={editAccountInstructionData.accountInstruction3}
                onChange={handleEditAccountInstruction}
                name="accountInstruction3"
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                variant="outlined"
                label="Customer ID"
                onChange={handleNewChange}
                disabled
                sx={{ display: "none" }}
                value={existingData.customerId}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              {filteredAccountProduct.length > 0 && (
                <>
                  <TextField
                    name="productCode"
                    onChange={handleNewChange}
                    variant="outlined"
                    value={existingData.productCode}
                    select
                    label="Select account product"
                    fullWidth
                    // className={`${
                    //   Object.keys(errors).includes("accountProductCode") &&
                    //   "error-input-z"
                    // }`}
                  >
                    <MenuItem>Select An Option</MenuItem>
                    {filteredAccountProduct.map((items, index) => {
                      return (
                        <MenuItem key={index} value={items.productCode}>
                          {`${items.productCode} -- ${items.productName}`}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </>
              )}
            </Grid>
            <Grid item>
              <br />
              <Button
                variant="contained"
                size="medium"
                sx={{ color: "#fff", bgcolor: "#D0021B", height: "40px" }}
                onClick={() => createAccountForExistingCustomer(existingData)}
              >
                Add product
              </Button>
            </Grid>
          </Grid>
          <BasicModal
            message={message}
            open={open}
            handleClose={newHandleClose}
            handleOpen={handleOpen}
            result={result}
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Grid container spacing={2}>
            <StartContribution details={details} />
          </Grid>
        </TabPanel>
      </div>
    </EditModal>
  );
};

export default EditData;
